<template>
  <div id="smooth-wrapper">
    <video autoplay muted loop id="background-video">
      <source src="~@/assets/images/data/cy.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>

    <visualEditor v-show="draggableType" @saveEditor="saveEditor" ref="editor" :style="{ height: '200px', width: '100%', position: 'fixed', top: '0px', zIndex: 1000 }"/>
    <div class="main" id="smooth-content"  ref="main">
      <div ref="main-header-top" class="main-header-top">
        <div class="main-header-nav">
          <div class="text-title">{{ currentTime }}</div>
          <div class="data-btn" v-if="showGoHome === true" @click="switchDepartment('内蒙古自治区农牧厅')" >
            <div class="text-title">返回</div>
          </div>
          <div class="data-btn" @click="dialogTableVisible = true"  v-if="isAdmin">
            <div class="text-title ">选择部门</div>
          </div>
        </div>
        <div class="main-header-nav" style="min-width: 900px">
        </div>
        <div class="main-header-nav">
          <div class="data-btn"  @click="editPageFunc()">
            <div class="text-title">进入编辑</div>
          </div>

          <div class="data-btn">
            <div class="text-title">
              <a href="/#/dept"> 个人中心</a>
            </div>
          </div>

        </div>
      </div>
      <div ref="main-header" class="main-header">
        <div class="main-header-bgimg">
          <h1>{{ title  }}   </h1>
        </div>
      </div>



      <div ref="main-body" class="main-body  scroll-container ">

        <!--        <Search style="width: calc(100vw - 78px);"></Search>-->

        <div class="engineBox" ref="engineBoxRef"  @wheel="handleWheel" style="padding-left: 250px;">
<!--          <div style="width: calc(100vw - 90vw);"></div>-->
<!--             <div style="width:5000px;background:red;height:100%;"></div>-->
<!--           <temp v-if="isAdmin" ></temp>-->
<!--          <TempDep v-else  :dep="getSchema"></TempDep>-->
          <dynamic-rendering-engine :data="getSchema?.value" ref="dynamicRefs" />
        </div>





<!--         <div style="width: 5vw"></div>-->
      </div>

      <div ref="main-footer" class="main-footer" v-if="0">
        <div class="main-footer-bgimg">
          <div class="data-btn" @click="gotoCard('news')">
            <div class="text-title">新闻板块</div>
          </div>
          <div class="data-btn">
            <div class="text-title">政策板块</div>
          </div>
          <div class="data-btn">
            <div class="text-title">简报制度</div>
          </div>
          <div class="data-btn">
            <div class="text-title">工作板块</div>
          </div>
          <div class="data-btn">
            <div class="text-title">一张图</div>
          </div>
          <div class="data-btn">
            <div class="text-title">地图板块</div>
          </div>
        </div>
      </div>


      <el-dialog :close-on-click-modal="false" custom-class="dialog" append-to-body modal-append-to-body lock-scroll modal style="z-index: 9999999;"
        title="选择部门" :visible.sync="dialogTableVisible">
        <div style="height: 40vh;overflow: auto;">
          <div v-for="( item, index) in depTree['children']" :key="index" style="margin-bottom: 16px">
            <dv-decoration-11 style="width:180px;height:60px;">{{ item['short_name'] }}</dv-decoration-11>
            <div class="item9" v-for="i in item['children']" @click="switchDepartment(i['name'])">{{
              i['short_name']
              }}</div>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>


<script>
import dynamicRenderingEngine from "./dynamicRenderingEngine"
import visualEditor from './visualEditor.vue'; // 添加编辑器组件
import { gaspMixin} from './mixin/gasp.mixin'; //动画
import { busMixin} from './mixin/bus.mixin';
import {mapActions, mapGetters} from 'vuex'
import Temp from "@/views/data/temp.vue";
import TempDep from "@/views/data/temp_dep.vue";


export default {
  mixins: [gaspMixin,busMixin],
  components:{
    Temp,TempDep,
    dynamicRenderingEngine,visualEditor
  },
  data() {
    return {
      draggableType:false,
      dialogTableVisible:false
    }
  },
  mounted() {
    // this.fetchData();
    this.init()//初始化动画
    this.busMain();//执行业务逻辑
    // this.handleScroll()
  },
  computed: {

    // ...mapGetters('schema', ['dataMapByKey']),

    currentDepartment() {
      let data = this.$store.getters["schema/schema"].value;
      //  data.assign
      return this.$store.getters["schema/schema"].value
    },
  },
  methods: {
    editPageFunc: function (e) {
      this.setGlobalType(true)
      this.$refs["main-body"].style.marginTop = "144px"
      this.$refs["engineBoxRef"].style.height = "75vh"
      this.draggableType = true
    },

    handleWheel(event) {
      console.log(event)
      let horizontalScroll = 0;
            if (event.deltaY < 0) { // 向下滚动
                horizontalScroll = -100; // 假设向右滚动100px
            } else if (event.deltaY > 0) { // 向上滚动
                horizontalScroll = 100; // 假设向左滚动100px
            }
             this.$refs["engineBoxRef"].scrollBy({
              left: horizontalScroll * 10, // 水平滚动距离
              top: 0,       // 垂直滚动距离（这里我们不需要垂直滚动）
              behavior: 'smooth' // 可选，使滚动更平滑（但可能不被所有浏览器支持）
            });

    },


    saveEditor:function () {
      // this.$refs["main-body"].style.marginTop = "0px"
      // this.draggableType = false
      // let data =  this.$refs.dynamicRefs.$props.data;
      // this.$refs.dynamicRefs.$props.data=[]

      //保存 json
      this.departmentsDataUpdate({
        "departments_id": this.depTree.id||"",
        "departments_name":this.depTree.name||"",
        "value": this.$refs.dynamicRefs.$props.data //更新创建 schema
      }).then(res=>{


        this.$message({
          message: '保存成功',
          type: 'success'
        });
      })

    },
    ...mapActions({
      departmentsDataUpdate:'schema/DepartmentsDataUpdate',
      setGlobalType:"setGlobalType"
    }),
  },

}


</script>


<style lang="scss">
.smooth-content {
  height: 100%;
  width: 100%;

}

.item9 {
  background: linear-gradient(to left, #2CD5FF, #2CD5FF) left top no-repeat,
    linear-gradient(to bottom, #2CD5FF, #2CD5FF) left top no-repeat,
    linear-gradient(to left, #2CD5FF, #2CD5FF) right top no-repeat,
    linear-gradient(to bottom, #2CD5FF, #2CD5FF) right top no-repeat,
    linear-gradient(to left, #2CD5FF, #2CD5FF) left bottom no-repeat,
    linear-gradient(to bottom, #2CD5FF, #2CD5FF) left bottom no-repeat,
    linear-gradient(to left, #2CD5FF, #2CD5FF) right bottom no-repeat,
    linear-gradient(to left, #2CD5FF, #2CD5FF) right bottom no-repeat;
  background-size: 1px 8px, 8px 1px, 1px 8px, 8px 1px;
  border: 1px solid #073F97;
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding-top: 2px;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
  width: 200px;
  height: 30px;

  &:hover {
    background-color: #2CD5FF;
    border: 1px solid #2CD5FF;
  }

}


.text-title {
  background: linear-gradient(0deg, #66FFFF 0%, #C3F8B3 100%);
  -webkit-background-clip: text;
  /*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent;
  /*给文字设置成透明*/
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
}

.data-btn {
  width: 132px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(314deg, rgba(0, 171, 210, 0.6) 0%, rgba(0, 114, 167, 0.27) 100%);
  border: 1px solid;
  border-image: linear-gradient(136deg, rgba(12, 209, 220, 0.22), rgba(5, 164, 182, 0.73)) 1 1;
  transform: skewX(30deg);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: linear-gradient(to top left, transparent 41%, #017396 50%, #03395cf0 51%, transparent 52%);
    pointer-events: none;
    /* 确保斜线不会干扰鼠标事件 */
  }

  div {
    transform: skewX(-30deg);
  }

  &>.text-title {
    color: #FFFFFF;
  }

  &:hover {
    background-color: #06a37e;
  }
}


.main {
  //background-color: rgb(4, 38, 55);
  //
  //background-image: url("https://p5.itc.cn/q_70/images01/20210830/ca6daed8c22543bc8712dbaa667b239b.jpeg");
  // background-size: cover ;
  background: transparent;
  color: #66FFFF;

}

#background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  //background: url('https://p5.itc.cn/q_70/images01/20210830/ca6daed8c22543bc8712dbaa667b239b.jpeg') no-repeat;
  background-size: cover;
}


.main-header-top {
  position: absolute;
  display: flex;
  justify-content: space-around;
  top: 0px;
  left: 0px;
  height: 68px;
  width: 100vw;
  z-index: 999;

  .main-header-nav {
    display: flex;
    align-items: center;

    &>div {
      margin-left: 1vw;
    }
  }

}

.main-header {
  // background-color: rgb(4, 38, 55);
  background-color: rgba(4, 38, 55, 0.5);
  /* Transparent background */
  //background-image: url("");
  background-size: cover;
  height: 70px;
  /* Footer height */
  display: flex;


  .main-header-bgimg {
    background: url("~@/assets/images/data/header-top.png") no-repeat;
    margin: 0 auto;
    width: 1018px;
    display: flex;
    align-items: center;
    justify-content: center;


    h1 {
      //color: #C3F8B3;
      background: linear-gradient(0deg, #66FFFF 0%, #C3F8B3 100%);
      -webkit-background-clip: text;
      /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent;
      /*给文字设置成透明*/
      font-size: 36px;
    }
  }

}

.main-footer {

  .main-footer-bgimg {
    //background-color: rgba(9, 60, 228, 0.5); /* Transparent background */
    background: url("~@/assets/images/data/footer.png") no-repeat;

    height: 94px;
    margin: 0 auto;
    width: 1920px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 6px;
    padding-left: 40px;

    &>div {
      margin-right: 16px;
    }

  }
}

.main-body {
  //height: calc(100vh - 100px - 72px);

  padding: 24px 0px;
  padding-bottom: 0px;
  padding-top: 80px;
  scroll-behavior: smooth;

  // overflow-x: scroll;
  //
  //background: transparent; /* Transparent background */

}


.scroll-container {
  display: flex;
  width: max-content;
  //overflow-x: auto;


}

.main-header-top,
.main-header,
.main-footer {
  position: fixed;
  width: 100%;
  z-index: 998;
  /* 确保它们在滚动内容之上 */
  left: 0;


}

.main-header-top {
  top: 0;
  z-index: 999;
  /* 确保它们在滚动内容之上 */
}

.main-header {
  top: 0;

  &--scrolled {
    height: 40px;
  }
}

.main-footer {
  bottom: 0;
  left: 0;
}

.dialog {
  width: 1178px;
  height: 666px;
  background: linear-gradient(180deg, #004146 0%, #000A25 10%, rgba(0, 10, 37, 0) 100%);
  border: 1px solid #66FFFF;
  backdrop-filter: blur(13px);
  color: #FFFFFF;

  .el-dialog__title,
  .el-dialog__body {
    color: #FFFFFF;
  }
}

.el-select-dropdown {
  z-index: 9999999 !important;
}
.engineBox{
  display: flex;
flex-flow:row;
  overflow-y: hidden;
  width: 100vw;
  overflow-x: scroll;
  height: 91vh;
  z-index: 1;

}


 //滚动条css
.engineBox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 40px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}

.engineBox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: black;
  z-index: 1;
}

// .engineBox::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   // box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
//   /*border-radius: 5px;*/
//   background: #fff;

// }

</style>
